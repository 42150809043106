import React, { forwardRef, HTMLProps, useState } from "react";
import styled from "styled-components";
import AddToListButton from "../../common/article/AddToListButton";
import ArticleName from "../../common/article/ArticleName";
import ArticleTileContainer from "../../common/article/ArticleTileContainer";
import ArticleTileDisplay from "../../common/article/ArticleTileDisplay";
import ArticleTileInfo from "../../common/article/ArticleTileInfo";
import { renderAttributeIcons } from "../../common/article/AttributeIcon";
import PriceDisplay from "../../common/article/PriceDisplay";
import ProductImage from "../../common/article/ProductImage";
import VariantsIcon from "../../common/icons/VariantsIcon";
import DeCardPoints from "../../common/article/DeCardPoints";
import { IFilter } from "../../contexts/FiltersContext";
import { ArticleDetailsWithVariantsFragment } from "../../generated/graphql";
import dontForward from "../../utils/dontForward";
import useBreakpointMatch from "../../hooks/useBreakpointMatch";
import valuesCss from "../../utils/valuesCss";

const ArticleTileContainerMain = styled(ArticleTileContainer).withConfig(dontForward("double", "clickable"))<{ double: boolean, clickable: boolean }>(({ double, clickable }) => ({
    gridColumn: double ? "span 2" : undefined,
    cursor: clickable ? "pointer" : undefined
}));

const AbsPosPriceDisplay = styled(PriceDisplay)<{ bigOffset: boolean }>(({ bigOffset, theme }) => ({
    position: "absolute",
    ...(bigOffset ? {
        bottom: theme.baseUnit * 2,
        right: theme.baseUnit * 2
    } : {
        bottom: -8,
        right: 0
    })
}));

const VariantsHint = styled((props: HTMLProps<HTMLDivElement>) => (
    <div {...props}><VariantsIcon />mehr Sorten</div>
))(({ theme }) => ({
    position: "absolute",
    top: theme.baseUnit,
    left: theme.baseUnit,
    background: theme.palette.white,
    boxShadow: "0 0 3px 3px rgb(255, 255, 255)",
    fontSize: 12,
    lineHeight: "16px",
    padding: valuesCss("px", 0, theme.baseUnit / 2),
    verticalAlign: "middle",
    "& > svg": {
        height: "1em",
        display: "inline-block",
        verticalAlign: "middle",
        marginRight: "0.5em"
    }
}));

const BottomLeftDeCardPoints = styled(DeCardPoints)<{ bigOffset: boolean }>(({ bigOffset }) => ({
    position: "absolute",
    ...(bigOffset ? ({
        bottom: 24,
        left: 0
    }) : ({
        bottom: 8,
        left: 0
    }))
}));

const BasePrice = styled("p")({
    fontSize: 12
});

interface IArticleTileProps {
    article: ArticleDetailsWithVariantsFragment;
    onClick?: () => void,
    onAddClick: () => void,
    isAdded: boolean,
    filters: Array<IFilter>;
    noExpand: boolean;
    clickable: boolean;
}

const ArticleTile = forwardRef<HTMLDivElement, IArticleTileProps>(({
    article: {
        id,
        name,
        descriptionLines,
        offeredPrice,
        normalPrice,
        special,
        baseUnitPrice,
        baseUnitPriceSammel,
        imageUrl,
        attributes,
        hideNormalPrice,
        hasVariants,
        deCardPoints
    },
    onClick,
    onAddClick,
    isAdded,
    filters,
    noExpand,
    clickable
}, ref) => {

    const isAtLeastSmall = useBreakpointMatch("sm");
    const [descriptionExpanded, setDescriptionExpanded] = useState(false);

    descriptionLines = Array.from(new Set(descriptionLines).values());
    const canExpandDescription = descriptionLines.length > 3;

    const shownDescriptionLines = !descriptionExpanded ?
        descriptionLines.slice(0, 3) :
        descriptionLines;

    const horizontal = !!special && isAtLeastSmall && !noExpand;

    return (
        <ArticleTileContainerMain
            ref={ref}
            id={id}
            horizontal={horizontal}
            double={!!special && !noExpand}
            clickable={clickable}
        >
            <ArticleTileDisplay horizontal={horizontal} onClick={onClick}>
                <ProductImage src={imageUrl || undefined} maxHeight={horizontal ? 265 : undefined} />
                <AbsPosPriceDisplay
                    offered={offeredPrice}
                    normal={normalPrice || null}
                    hideNormalPrice={hideNormalPrice}
                    bigOffset={!!special && !noExpand}
                    special={special}
                />
                {!!deCardPoints && (
                    <BottomLeftDeCardPoints points={deCardPoints} bigOffset={!!special && !noExpand} />
                )}
            </ArticleTileDisplay>
            <ArticleTileInfo
                horizontal={horizontal}
                paddingTop={true}
                onClick={(canExpandDescription && !descriptionExpanded) ? () => setDescriptionExpanded(!descriptionExpanded) : onClick}
            >
                <ArticleName>{name}{renderAttributeIcons(attributes, filters)}</ArticleName>
                {shownDescriptionLines.map(l => <p key={l}>{l}</p>)}
                {baseUnitPriceSammel && (
                    <BasePrice>Grundpreis: {baseUnitPriceSammel}</BasePrice>
                )}
                {canExpandDescription && !descriptionExpanded && (
                    "... mehr"
                )}
            </ArticleTileInfo>
            <AddToListButton
                added={isAdded}
                onClick={onAddClick}
            />
            {hasVariants && (
                <VariantsHint onClick={onClick} />
            )}
        </ArticleTileContainerMain>
    );
});

export default React.memo(ArticleTile);