import React from "react";
import styled from "styled-components";

const DeCardPointsBase = styled("div")({
    position: "relative",
    width: 68,
    height: 48,
    "& > img": {
        objectFit: "contain",
        objectPosition: "center"
    }
});

const Points = styled("div")({
    fontFamily: "'Amsi Pro Cond', sans-serif",
    fontSize: 18,
    color: "rgb(255, 255, 255)",
    lineHeight: "1",
    textAlign: "center",
    position: "absolute",
    top: 28,
    left: 0,
    right: 0
});

interface IDeCardPointsProps {
    className?: string;
    points: number;
}

const DeCardPoints: React.FC<IDeCardPointsProps> = ({ className, points }) => (
    <DeCardPointsBase className={className}>
        <img src="/assets/images/PaybackButton.svg" />
        <Points>{points}</Points>
    </DeCardPointsBase>
);

export default DeCardPoints;